import {
  computed,
  ComputedRef,
  onBeforeMount,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api';
import {
  getStoryblokImageURL,
  getStoryBlokVersion,
  useContent,
} from '@unified-commerce/gpc-vue-storefront-storyblok';
import kebabCase from 'lodash/kebabCase';

import { IBannerImage } from '~/components/@types/IBannerImage';
import { DocumentNode } from '~/components/CMS/RichTextDisplay/types';

interface BrandContent {
  fullSlug: ComputedRef<string>;
  name: ComputedRef<string>;
  description: ComputedRef<string | DocumentNode>;
  logoSrc: ComputedRef<string>;
  logoAlt: ComputedRef<string>;
  banners: ComputedRef<IBannerImage[]>;
}

interface UseBrandContent {
  loading: ComputedRef<boolean>;
  error: ComputedRef<string | null>;
  brand: BrandContent;
}

const useBrandContent = (vendor: string): UseBrandContent => {
  const route = useRoute();
  const { $config } = useContext();
  const version = getStoryBlokVersion($config.ENV, route.value);
  const { slug } = route.value.params;
  const { search, content = {}, loading, error } = useContent(`brandSearch-${slug}`);

  onBeforeMount(async () => {
    await search({
      version,
      url: `brands/${kebabCase(vendor)}`,
      relations: 'BrandHero.brand_video,BrandHero.brand',
      optimisticStoryLoading: true,
    });
  });
  const brandContent = computed(() => content.value?.content?.[0]?.brand?.content);

  const fullSlug = computed(() => content.value?._meta?.full_slug ?? '');
  const name = computed(() => brandContent.value?.name);
  const description = computed(() => brandContent.value?.description);
  const banners = computed(() => brandContent.value?.banner?.[0]?.banners ?? []);
  const logoSrc = computed(() => getStoryblokImageURL(brandContent.value?.logo?.filename));
  const logoAlt = computed(() =>
    brandContent.value?.logo?.alt
      ? brandContent.value?.logo?.alt
      : `${brandContent.value?.name} logo`,
  );

  return {
    loading: computed(() => loading.value),
    error: computed(() => error.value?.search?.message ?? null),
    brand: {
      fullSlug,
      name,
      description,
      banners,
      logoSrc,
      logoAlt,
    },
  };
};

export default useBrandContent;
