import { computed, onBeforeMount, useContext, useRoute } from '@nuxtjs/composition-api';
import { getStoryBlokVersion, useAllContent } from '@unified-commerce/gpc-vue-storefront-storyblok';

import { ISBSizeGuide } from '~/components/@types/ISBSizeGuide';
import { selectSizeGuide } from '~/composables/useSizeGuide/selectSizeGuide';
import { UseSizeGuideConfig } from '~/composables/useSizeGuide/types';

const useSizeGuide = (config?: UseSizeGuideConfig) => {
  const route = useRoute();
  const { $config } = useContext();
  const { slug } = route.value.params;

  const { search, content, loading, error } = useAllContent(`size-guide-${slug}`);

  onBeforeMount(async () => {
    await search({
      startsWith: 'content/size-guides',
      version: getStoryBlokVersion($config.ENV, route.value),
      filterQuery: {
        component: {
          in: 'SizeGuide',
        },
      },
      resolveRelations: 'SizeGuide.brand',
      optimisticStoryLoading: true,
    });
  });

  const sizeGuide = computed<ISBSizeGuide | null>(() =>
    selectSizeGuide(
      content.value.map((story) => story.content),
      config,
    ),
  );

  return {
    loading,
    error,
    sizeGuide,
  };
};

export default useSizeGuide;
