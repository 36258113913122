import { onMounted, Ref, ref, watch } from '@nuxtjs/composition-api';

import { AustralianState, StoreDetails } from '~/types/stores';

import hardCodedStoreDetails from '../../data/storeDetails.json';

function useStoresList(selectedState?: Ref<AustralianState>): {
  stores: Ref<StoreDetails[] | null>;
  error: Ref<unknown | null>;
} {
  const stores = ref<StoreDetails[] | null>(null);
  const error = ref<unknown | null>(null);

  const fetchStores = (state?: AustralianState) => {
    error.value = null;
    try {
      stores.value = state
        ? (hardCodedStoreDetails as { [state: string]: StoreDetails[] })[state]
        : Object.values(hardCodedStoreDetails).flat();
    } catch (err) {
      error.value = err;
      console.error(err);
    }
  };

  onMounted(() => {
    fetchStores(selectedState?.value);
  });

  if (selectedState) {
    watch(selectedState, (newState) => {
      fetchStores(newState);
    });
  }

  return { stores, error };
}

export default useStoresList;
