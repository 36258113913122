import { computed, ComputedRef, useRoute } from '@nuxtjs/composition-api';
import { productGetters, useProduct } from '@unified-commerce/gpc-vue-storefront-shopify';
import { ProductsSearchParams } from '@vue-storefront/core';

import { ProductViewModel } from '~/components/@types/ProductViewModel';
import { getViewModelProperties } from '~/composables/useShopifyProduct/getViewModelProperties';

interface Error {
  name: string;
  message: string;
  stack?: string;
}

interface UseShopifyProduct {
  fetchProduct: (slug: string, params: ProductsSearchParams) => Promise<void>;
  loading: ComputedRef<boolean>;
  error: ComputedRef<Error | undefined>;
  product: ProductViewModel;
  tempRawProduct: any;
}

const useShopifyProduct = (): UseShopifyProduct => {
  const route = useRoute();
  const { slug } = route.value.params;
  const { products, loading, error, search } = useProduct(slug);
  const product = computed(() => productGetters.getFiltered(products.value)[0]);

  const fetchProduct = (slug: string, params?: ProductsSearchParams): Promise<void> =>
    search({
      slug,
      ...params,
    });

  return {
    fetchProduct,
    loading: computed(() => loading.value),
    error: computed(() => error.value?.search),
    product: getViewModelProperties(product),
    tempRawProduct: product,
  };
};

export default useShopifyProduct;
