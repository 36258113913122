import { ISBSizeGuide } from '~/components/@types/ISBSizeGuide';
import { UseSizeGuideConfig } from '~/composables/useSizeGuide/types';

const matchingProduct = (config: UseSizeGuideConfig) => (content: ISBSizeGuide) =>
  content?.product?.[0]?.shopify_product.items[0]?.id === config.productId;

const hasMatchingBrand = (config: UseSizeGuideConfig) => (content: ISBSizeGuide) => {
  if (typeof content.brand === 'string') return false;
  const brandCategory =
    content?.brand?.content?.product_category[0].shopify_category.items[0].handle;
  if (!brandCategory) {
    return false;
  }
  return config.categoryHandles.includes(brandCategory);
};

const hasNonMatchingBrand = (config: UseSizeGuideConfig) => (content: ISBSizeGuide) => {
  if (typeof content.brand === 'string') return false;
  const brandCategory =
    content?.brand?.content?.product_category[0].shopify_category.items[0].handle;
  if (!brandCategory) {
    return false;
  }
  return !config.categoryHandles.includes(brandCategory);
};

const hasMatchingCategory = (config: UseSizeGuideConfig) => (content: ISBSizeGuide) => {
  const categoryHandles = (content?.category ?? []).flatMap((category) =>
    category.shopify_category.items.map(({ handle }) => handle),
  );
  if (!categoryHandles.length) {
    return false;
  }
  if (hasNonMatchingBrand(config)(content)) {
    return false;
  }

  return config.categoryHandles.some((handle) => categoryHandles.includes(handle));
};

const findFirstGeneric = (content: ISBSizeGuide) =>
  content.brand === '' && !content.category.length && !content.product.length;

export const selectSizeGuide = (
  sizeGuides: ISBSizeGuide[],
  config?: UseSizeGuideConfig,
): ISBSizeGuide | null => {
  if (!config) {
    return sizeGuides.find(findFirstGeneric) ?? null;
  }

  const productSpecificSizeGuide = sizeGuides.find(matchingProduct(config));
  if (productSpecificSizeGuide) {
    return productSpecificSizeGuide;
  }

  const brandSizeGuides = sizeGuides.filter(hasMatchingBrand(config));
  const brandAndCategorySizeGuides = brandSizeGuides.filter(hasMatchingCategory(config));
  if (brandAndCategorySizeGuides[0]) {
    return brandAndCategorySizeGuides[0];
  }
  if (brandSizeGuides[0]) {
    return brandSizeGuides[0];
  }

  const categorySizeGuides = sizeGuides.filter(hasMatchingCategory(config));
  if (categorySizeGuides[0]) {
    return categorySizeGuides[0];
  }

  return sizeGuides.find(findFirstGeneric) ?? null;
};
