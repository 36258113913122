import { Ref, ref } from '@nuxtjs/composition-api';

import { useLocalStorage } from '~/composables/index';
import { StoreDetails } from '~/types/stores';

const LOCAL_STORAGE_KEY = 'myStore';

const myStore = ref<StoreDetails | null>(null);

const storeShortName = (name: string) => name.replace('AMX Superstores ', '');

const useMyStore = (): {
  myStore: Ref<StoreDetails | null>;
  saveMyStore: (store: StoreDetails) => void;
} => {
  const { load, save } = useLocalStorage();
  myStore.value = load(LOCAL_STORAGE_KEY);

  const saveMyStore = (store: StoreDetails): void => {
    const storeToSave = {
      ...store,
      shortName: storeShortName(store.name),
    };
    myStore.value = storeToSave;
    save(LOCAL_STORAGE_KEY, storeToSave);
  };

  return { myStore, saveMyStore };
};

export default useMyStore;
