import { Ref, ref } from '@nuxtjs/composition-api';

import { useLocalStorage } from '~/composables';
import { LatLng } from '~/types/stores';

const myLocation = ref<LatLng | null>(null);

const LOCAL_STORAGE_KEY = 'myLocation';

const useMyLocation = (): {
  myLocation: Ref<LatLng | null>;
  saveLocation: (store: LatLng) => void;
} => {
  const { save, load } = useLocalStorage();
  myLocation.value = load(LOCAL_STORAGE_KEY);

  const saveLocation = (latLng: LatLng): void => {
    myLocation.value = latLng;
    save(LOCAL_STORAGE_KEY, latLng);
  };

  return { myLocation, saveLocation };
};

export default useMyLocation;
